<template>
  <div>
    <headGoBack :iCustomizdde="true">
      <template #default>
        <span> オプションサービス </span>
      </template>
    </headGoBack>
    <!-- <div class="top"></div> -->
    <!--普检精检详细说明 -->
    <div class="head">
      <!--      <div class="base-width">-->
      <!--        <h2>RAKUMART 公司费用介绍</h2>-->
      <!--        <p>我们秉承公正、透明的原则制定了以下费用标准，有针对不同等级的客户设定不同的收费标准。</p>-->
      <!--        <p>您的会员等级更高，就能享有更优惠的费用。</p>-->
      <!--      </div>-->
      <div class="base-width">
        <h2>オプションサービス</h2>
        <p>
          Rakumartでは、さまざまな倉庫（FBA、楽天、Yahooなど）でのラベル貼り付け納品サービスや製品のカスタム撮影、ODM（Original
          Design Manufacturer）カスタマイズなど、追加サービスを提供しています。
        </p>
        <p>独自のプライベートブランドをお持ちであれば、ぜひご連絡ください。</p>
      </div>
    </div>
    <!-- 收费说明 -->
    <div class="collectFee">
      <div class="width920">
        <!--        <h3>{{ $fanyi("收费说明") }}</h3>-->
        <h3>詳細</h3>
        <!--        <p class="text">{{ $fanyi("RAKUMART在交易的过程中会向客户收取手续费。") }}<br>-->
        <!--          {{ $fanyi("手续费的收取标准是根据订单金额百分比进行计算，百分比大小与客户的会员等级有关。") }}-->
        <!--        </p>-->
        <!--        <p class="text">{{-->
        <!--            $fanyi("请注意手续费只是基本工作的费用。")-->
        <!--          }}<br>{{ $fanyi("对于下列费用一览表中没有的操作与服务，我们也会灵活应对，请您有问题务必随时咨询。") }}-->
        <!--        </p>-->

        <p class="text">RAKUMARTは商品を販売している訳ではございません。</p>
        <p class="text">
          買付代行サービスとしてお客様に手数料を頂いております。<br />手数料は注文金額に基づいて計算されますが、手数料はお客様の会員レベルによって異なります。
        </p>
        <p class="text">
          下記の料金表に掲載されていない操作やサービスについても柔軟に対応いたしますので、ご質問がある場合はお気軽にお問い合わせください。
        </p>
        <div class="scrollBox">
          <div class="table">
            <div class="row row-title">
              <div class="label">
                <div class="grade">{{ $fanyi("项目") }}</div>
              </div>
              <div class="item">
                <div class="grade">{{ $fanyi("普通会员") }}</div>
              </div>
              <div class="item">
                <div class="grade">{{ $fanyi("定额会员") }}</div>
              </div>
              <div class="item">
                <div class="grade">SVIP</div>
              </div>
            </div>
            <div class="row">
              <div class="label">{{ $fanyi("简易检品") }}</div>
              <div class="item">無料</div>
              <div class="item">無料</div>
              <div class="item">無料</div>
            </div>
            <div class="row">
              <!--          <div class="label">{{ $fanyi("详细检品（表+里）") }}</div>-->
              <div class="label" style="flex-direction: column">
                <div class="flex" style="width: 100%">
                  <div>{{ $fanyi("詳細検品") }}</div>
                  <div
                    class="colorB4272B cursorPointer"
                    @click="pageJump('/inspectionDetail')"
                  >
                    詳細へ
                  </div>
                </div>
                <div style="width: 100%">
                  <span class="colorB4272B" style="">※（一部例外有り）</span
                  ><br />
                  <span
                    class="colorB4272B"
                    style="display: inline-block; margin-left: 0"
                    >単価１５元以下の商品１点1.5元</span
                  ><br />
                  <span
                    class="colorB4272B"
                    style="display: inline-block; margin-left: 0"
                    >単価３０元以下の商品１点２元</span
                  >
                </div>
              </div>
              <div class="item">6%</div>
              <div class="item">5%</div>
              <div class="item">4%</div>
            </div>
            <!--          <div class="row" style="height: 30px">-->
            <!--            <div class="label"></div>-->
            <!--            <div class="item"></div>-->
            <!--            <div class="item"></div>-->
            <!--            <div class="item"></div>-->
            <!--          </div>-->
            <!--          <div class="row">-->
            <!--            <div class="label" style="flex-direction: column">-->
            <!--              <div style="width: 100%;">{{ $fanyi("詳細検品（時間換算対応）") }}</div>-->
            <!--              <div style="width: 100%;margin-bottom:10px">-->
            <!--                <span class="colorB4272B" style="">特殊商品検品：15元/30分</span><br>-->
            <!--                <span class="colorB4272B" style="display: inline-block;margin-left: 0">※既定の検品料金で採算が合わない検品について、</span><br>-->
            <!--                <span class="colorB4272B" style="display: inline-block;margin-left: 0">時間検品料金を適用させて頂く場合がございます。</span><br>-->
            <!--                <span class="colorB4272B" style="display: inline-block;margin-left: 0">例）電器機器等の通電や無線接続確認等</span>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="item">相談可</div>-->
            <!--            <div class="item">O</div>-->
            <!--            <div class="item">O</div>-->
            <!--          </div>-->
            <div class="row">
              <div class="label">不良品返品/交換手数料</div>
              <div class="item">無料</div>
              <div class="item">無料</div>
              <div class="item">無料</div>
            </div>
            <div class="row" style="height: 10px">
              <div class="label"></div>
              <div class="item"></div>
              <div class="item"></div>
              <div class="item"></div>
            </div>
            <div class="row">
              <div class="label">
                <div style="width: 100%">
                  <span>不良品返品/交換中国国内送料</span><br />
                  <span
                    class="colorB4272B"
                    style="display: inline-block; margin-left: -6px"
                    >（※セラーが受け付けない及びお客様都合の</span
                  ><br />
                  <span
                    class="colorB4272B"
                    style="display: inline-block; margin-left: 1px"
                  >
                    返品時に発生、明らかな不良はセラーが負担）</span
                  >
                </div>
              </div>
              <div class="item">5元/㎏</div>
              <div class="item">5元/㎏</div>
              <div class="item">5元/㎏</div>
            </div>
            <div class="row">
              <div class="label">
                <div class="flex">
                  <div>{{ $fanyi("写真撮影") }}</div>
                  <div
                    class="colorB4272B cursorPointer"
                    @click="pageJump('/photography')"
                  >
                    詳細へ
                  </div>
                </div>
              </div>
              <div class="item">O</div>
              <div class="item">O</div>
              <div class="item">O</div>
            </div>
            <div class="row">
              <div class="label">
                <div class="flex">
                  <div>{{ $fanyi("订单手续费") }}</div>
                  <div
                    class="colorB4272B cursorPointer"
                    @click="pageJump('/comisiones-rakumart')"
                  >
                    詳細へ
                  </div>
                </div>
              </div>
              <div class="item">商品代金3%-5%</div>
              <div class="item">無料</div>
              <div class="item">無料</div>
            </div>
            <div class="row">
              <div class="label">{{ $fanyi("入RAKUMART仓库费用") }}</div>
              <div class="item">無料</div>
              <div class="item">無料</div>
              <div class="item">無料</div>
            </div>
            <div class="row">
              <div class="label">{{ $fanyi("出RAKUMART仓库费用") }}</div>
              <div class="item">無料</div>
              <div class="item">無料</div>
              <div class="item">無料</div>
            </div>
            <!--          <div class="row">-->
            <!--            <div class="label ">-->
            <!--              <div>{{ $fanyi("精细检品（表面）") }}</div>-->
            <!--              <div class="colorB4272B colorB4272B detail" @click="pageJump('/inspectionDetail')">詳細</div>-->
            <!--            </div>-->
            <!--            <div class="item">2元/点</div>-->
            <!--            <div class="item">2元/点</div>-->
            <!--            <div class="item">2元/点</div>-->
            <!--          </div>-->
            <!--          <div class="row">-->
            <!--            <div class="label">-->
            <!--              <div>-->
            <!--                <span>チラシ、感謝手紙など印刷物入れ</span> <br>-->
            <!--                <span class="colorB4272B" style="display: inline-block;padding-left: -5px">（1種類のみ、2種類以上はセット作業代請求）</span>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="item">0.5元/点</div>-->
            <!--            <div class="item">0.3元/点</div>-->
            <!--            <div class="item">無料</div>-->
            <!--          </div>-->
            <!--          <div class="row">-->
            <!--            <div class="label">-->
            <!--              <div>-->
            <!--                <p> {{ $fanyi("商品组套操作费用") }} </p>-->
            <!--                <p>（{{ $fanyi("数量和种类") }}）</p>-->
            <!--                <span style="display: inline-block;"-->
            <!--                      class="colorB4272B">※定額会員、SVIPの場合、<br><span-->
            <!--                    style="display: inline-block;">商品内容により個別相談可能</span></span>-->
            <!--              </div>-->

            <!--            </div>-->
            <!--            <div class="item">3点以内:1元 <br/> 4~5点:2元 <br/>6~7点:3元 <br/>8~9点:4元<br/> 10点以上:7元-->
            <!--            </div>-->
            <!--            <div class="item">3点以内:1元 <br/> 4~5点:2元 <br/>6~7点:3元 <br/>8~9点:4元<br/> 10点以上:6元-->
            <!--            </div>-->
            <!--            <div class="item">3点以内:1元 <br/> 4~5点:1.5元 <br/>6~7点:2.5元 <br/>8~9点:3.5元<br/> 10点以上:5元-->
            <!--            </div>-->
            <!--          </div>-->
            <!--          <div class="row">-->
            <!--            <div class="label">RCEP原産地証明書</div>-->
            <!--            <div class="item">150元/回</div>-->
            <!--            <div class="item">150元/回</div>-->
            <!--            <div class="item">150元/回</div>-->
            <!--          </div>-->
          </div>
        </div>
      </div>
    </div>
    <!-- 检品option费 -->
    <div class="option" v-for="(item, index) in optionText" :key="index">
      <div class="base-width">
        <h3>{{ $fanyi(item.title) }}</h3>
        <div class="text" v-for="(text, textIndex) in item.textList">
          <p v-html="text.text"></p>
        </div>
        <div class="scrollBox" v-if="!item.type">
          <div class="table" v-if="!item.type">
            <div class="row row-title">
              <div class="label">
                <div class="grade">{{ $fanyi("项目") }}</div>
              </div>
              <div class="item">
                <div class="grade">{{ $fanyi("普通会员") }}</div>
              </div>
              <div class="item">
                <div class="grade">{{ $fanyi("定额会员") }}</div>
              </div>
              <div class="item">
                <div class="grade">SVIP</div>
              </div>
              <div class="item append">
                {{ $fanyi("视频") }}
              </div>
              <div class="item append">
                {{ $fanyi("图片") }}
              </div>
            </div>
            <div class="row" v-for="(items, indexs) in item.row" :key="indexs">
              <div class="label">
                <p v-html="items.label"></p>
              </div>
              <div class="item">
                <p v-html="items.val3"></p>
              </div>
              <div class="item">
                <p v-html="items.val2"></p>
              </div>
              <div class="item">
                <p v-html="items.val1"></p>
              </div>
              <div class="item append">
                <div
                  class="video"
                  v-if="items.video !== ''"
                  @click="lookVideo(items.video)"
                >
                  <!-- <video
                    :src="items.video"
                    :class="'itemsVideo' + items.id"
                    style="pointer-events: none"
                    @click="videoFullscreen('.' + 'itemsVideo' + items.id)"
                  ></video> -->
                  <!-- 2023/7/15 全部视频加载完成需要5-6分钟,苹果手机有加载事件限制所以不放视频而是翻一个按钮在这里 -->
                  <button
                    class="lookVideoBtn"
                    @click="videoFullscreen('.' + 'itemsVideo' + items.id)"
                  >
                    <img :src="require('@/assets/icon/playVideo.png')" alt="" />
                  </button>
                </div>
                <div class="img" v-else>
                  <img src="../../../assets/zunb.png" alt="" />
                </div>
              </div>
              <div class="item append">
                <div class="img" v-if="items.img !== ''">
                  <van-image
                    class="userRegister"
                    lazy-load
                    :src="items.img"
                    @click="$lookImg(items.img)"
                  >
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                </div>
                <div class="img" v-else>
                  <img src="../../../assets/zunb.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 検品について -->
        <div v-else class="inspect">
          <div class="item">
            <div class="img">
              <img src="@/assets/foot-img/optionExplain/des1.png" alt="" />
            </div>
            <div class="text">
              <h6>簡易（無料）検品</h6>
              <p>
                全ての入荷商品に対して、<br />
                簡易検品を行っています。
              </p>
              <p class="colorB4272B">★注文通りの納品かどうか</p>
              <p class="colorB4272B">★外観のダメージ有無</p>
              <p class="colorB4272B">★商品ページの商品と大きな</p>
              <p>
                主に上記３点になります。<br />
                問題があれば、お客様に報告して返品・返金交渉を無料で行います
              </p>
              <p class="colorB4272B">
                ※店舗より返品送料負担しない場合があります
              </p>
            </div>
          </div>
          <div class="item">
            <div class="img">
              <img src="@/assets/foot-img/optionExplain/des2.png" alt="" />
            </div>
            <div class="text">
              <h6>詳細（有料）検品</h6>
              <p>
                簡易検品は開封しませんが、詳細検品は開封の上、中身を確認します。
              </p>
              <p>アパレル商品であれば、汚れや傷の有無、チャックの開閉、</p>
              <p>糸きり等の作業も行います。</p>
              <p>利用料は商品金額の６％となります。※（一部例外有り）</p>
              <p>単価１５元以下の商品１点1.5元</p>
              <p>単価３０元以下の商品１点２元</p>
              <p>とさせていただきます。</p>
              <p>
                問題があれば、お客様に報告して返品・返金交渉を無料で行います。
              </p>
              <p>
                商品の採寸、作動、通電検査などは含まれませんのでご注意ください。
              </p>
              <p>必要の場合はなからず担当者または注文書に記載し、</p>
              <p>
                別途料金が必要の場合はお客様と再確認になりますので、予めご了承ください。
              </p>
              <p>検品料金と別に作業時間で請求させていただきます。</p>
              <p>３０分/１５元</p>
              <p class="colorB4272B">
                ※店舗より返品送料負担しない場合があります。
              </p>
              <p style="margin-top: 20px">更新日：2023年7月17日</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FootVue />
  </div>
</template>
<script>
import FootVue from "@/components/footBar";
import headGoBack from "@/components/headGoBack";
// import PicZoom from "vue-piczoom";
import { optionText } from "@/utlis/option-text";

export default {
  data() {
    return {
      optionText,
    };
  },

  components: {
    // PicZoom,
    FootVue,
    headGoBack,
  },
  methods: {
    lookVideo(vio) {
      let toCodeVio = this.$fun.toCode(vio);
      // 这里必须要使用vue路由跳转页面,因为重新加载页面之后浏览器不会让你自动播放有声音的视频,需要用户有交互才可以自动播放有声音的视频
      // this.$router.push(`/ProductDetails-watchVideo?url=${toCodeVio}`);
      window.open(vio);
    },
    //点击跳转
    pageJump(item) {
      window.open(item);
    },
    //视频全屏
    videoFullscreen(val) {
      const el = document.querySelector(val); // 获取需要全屏的元素
      if (!document.fullscreenElement) {
        // 判断是否已经处于全屏模式
        el.requestFullscreen().catch((err) => {
          // 进入全屏模式
          console.log(`进入全屏模式失败：${err.message}`);
        });
      } else {
        document.exitFullscreen(); // 退出全屏模式
      }
    },
    imgFullscreen(val) {
      const el = document.querySelector(val); // 获取需要全屏的元素
      if (!document.fullscreenElement) {
        // 判断是否已经处于全屏模式
        el.requestFullscreen().catch((err) => {
          // 进入全屏模式
          console.log(`进入全屏模式失败：${err.message}`);
        });
      } else {
        document.exitFullscreen(); // 退出全屏模式
      }
    },
  },
};
</script>
<style lang="scss" scoped>
:deep().colorB4272B {
  font-size: 20px;
  color: #b4272b;
  margin-top: 10px;
  line-height: 1.2;
}
.base-width {
  width: 750px;
  margin: 0 auto;
}
* {
  font-size: 24px;
  line-height: 1.4;
}
.scrollBox {
  max-width: 750px;
  overflow: auto;
  padding: 0 30px;
}
.top {
  height: 40px;
  background: #b4272b;
  min-width: 750px;
}

.head {
  margin: 0 auto;
  padding: 0 50px 0 30px;
  height: 450px;
  background: url("https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/64911ed2452ae.jpg")
    no-repeat;
  background-size: 100% 100%;
  background-position: 50%;
  min-width: 750px;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 36px;
    padding: 0px 0 50px;
    font-weight: 600;
    color: #ffffff;
    text-shadow: 0px 0px 27px rgba(0, 0, 0, 0.4);
  }

  p {
    font-size: 28px;
    line-height: 32px;
    font-weight: 400;
    color: #ffffff;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);

    &:nth-child(2) {
      margin-bottom: 25px;
    }
  }
}

h3 {
  font-size: 30px;
  padding: 80px 0 50px;
  font-weight: 600;
  color: #333333;
  text-align: center;
}

.vip {
  width: 100%;
  display: flex;
  padding-left: 296px;
  margin-bottom: 18px;

  .item {
    //width: 350px;
    flex: 1;
    border-radius: 10px;
    margin-left: 18px;
    text-align: center;
    background: linear-gradient(0deg, #a6a6a6, #d6d6d6);
    color: #fff;
    padding: 22px 0;

    &:nth-child(2) {
      background: linear-gradient(0deg, #f16e2e, #ffab82);
    }

    &:nth-child(3) {
      background: linear-gradient(0deg, #da4e3e, #ff897c);
    }

    .grade {
      font-size: 26px;
      font-weight: 600;
    }
  }
}

.text {
  margin-bottom: 50px;
  line-height: 1.7;
  font-size: 24px;
  text-align: center;
  padding: 0 30px;
}

.collectFee {
  background-color: #fff;
  padding-bottom: 80px;
  min-width: 750px;

  .width920 {
    width: 750px;

    .table {
      border-radius: 10px;
      overflow: hidden;
      width: 1200px;
    }
  }

  .row {
    display: flex;
    padding: 0;

    .label {
      font-size: 24px;
      flex: 1.6 !important;
      font-weight: 600;
      color: #333333;
      display: flex;
      justify-content: start;
      align-items: center;
      padding-left: 30px;
      position: relative;
      .detail {
        position: absolute;
        top: 0;
        right: 60px;
        padding: 20px 0;
      }
    }

    .item {
      flex: 1;
      padding: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0 !important;
      text-align: center;
    }

    .item:nth-child(3) {
      background: #fdf7ed;
      color: #684f3e;
    }

    .item:nth-child(4) {
      background: #ededfb;
      color: #2f3ac7;
    }
  }

  .row-title {
    font-weight: 600;
    height: 90px;

    .label {
      background: #eeeeee;
    }
    .grade {
      font-size: 26px;
    }
    .item:nth-child(2) {
      background: linear-gradient(165deg, #e6e6e6, #d0d0d0);
      color: #333333;
    }

    .item:nth-child(3) {
      background: linear-gradient(165deg, #f6e1b4, #d9c086);
      color: #684f3e;
    }

    .item:nth-child(4) {
      background: linear-gradient(165deg, #7591fa, #2f3ac7);
      color: #ffffff;
    }
  }
}

.option {
  padding-bottom: 80px;

  &:nth-child(odd) {
    background-color: #fff;
  }

  .table {
    margin: 0 20px;
    background-color: #fff;
    border-radius: 10px;
    // overflow: hidden;
    position: relative;
    width: 1400px;
    .row-title {
      background: #eeeeee;
      font-weight: 600;
      font-size: 18px;
      left: 0;
      z-index: 1;
      border-radius: 10px 10px 0 0;

      .item {
        padding: 26px 0;
        height: 90px;
      }

      .label {
        font-size: 26px;
        height: 90px;
      }

      .item:nth-child(2) {
        background: #fdf7ed;
        color: #333333;
        background: linear-gradient(165deg, #e6e6e6, #d0d0d0);
      }

      .item:nth-child(3) {
        background: linear-gradient(165deg, #f6e1b4, #d9c086);
        color: #684f3e;
      }

      .item:nth-child(4) {
        background: linear-gradient(165deg, #7591fa, #2f3ac7);
        color: #ffffff;
      }
    }
  }

  .row {
    display: flex;
    background-color: #fff;

    .item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 14px 0;

      .img {
        width: 128px;
        height: 72px;
        background: #c2c2c2;
        border-radius: 10px;
        cursor: pointer;

        img {
          width: 100%;
          border-radius: 10px;
          height: 100%;
        }
      }

      .video {
        width: 128px;
        height: 72px;
        background: #435cb8;
        border-radius: 10px;
        cursor: pointer;

        video {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
      p {
        /deep/ span {
          font-size: 24px;
        }
      }
    }

    .item:nth-child(3) {
      background: #fdf7ed;
      color: #684f3e;
    }

    .item:nth-child(4) {
      background: #ededfb;
      color: #2f3ac7;
    }

    .label {
      font-weight: 600;
      font-size: 16px;
      width: 320px;
      padding-left: 30px;
      display: flex;
      align-items: center;
      /deep/ span {
        font-size: 18px;
      }
    }

    .append {
      flex: 0.9;
      .lookVideoBtn {
        color: white;
        background-color: transparent;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 24px;
        white-space: nowrap;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .inspect {
    margin-top: 60px;
    padding: 0 50px;

    .item {
      display: flex;

      .img {
        margin-right: 59px;
      }

      .text {
        text-align: left;

        margin-bottom: 80px;

        h6 {
          font-size: 28px;
          margin-bottom: 30px;
          font-weight: 600;
          line-height: 20px;
        }

        p {
          line-height: 22px;
          font-size: 24px;
        }
      }
    }
  }
}
</style>
